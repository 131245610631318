<template>
  <div class="page-height">
    <div class="flex-center market-top">
      <div class="w1200">
        <div class="market-top-cont">
          <div class="page-title">算法超市</div>
          <div class="box-info">
            集中算法资源，满足产业从业者多样化需求，促进产业合作的同时为产业革新带来更多选择的数据支撑
          </div>
          <div class="search-line">
            <el-input
                @change="initData"
                @clear="initData"
                placeholder="请输入内容"
                v-model="keywords"
                clearable>
            </el-input>
            <i class="iconfont icon-icon-search_7eb btn-shadow-color" @click="initData"></i>
          </div>
          <div class="search-toast">
            <i class="iconfont icon-huo icon1"></i>
            <div>热门场景：</div>
            <div v-for="item in sceneList" @click="fastSearch(item.common_scene)">{{ item.scene_name }}</div>
<!--            <i class="iconfont icon-bg-right icon2 btn-shadow-color"></i>-->
          </div>
        </div>
      </div>
    </div>
    <div class="flex-center market-box">
      <div v-loading="loadStatus" class="w1200 flex-between market-list">
        <template v-if="list.length">
          <router-link v-for="item in list" class="market-item" :to="{ path: '/superMarket/detail', query: { name:  item.modelname }}" >
            <div class="market-item-title">{{ item.modelname }}</div>
            <div class="market-item-info limit2">{{ item.modeldesc }}</div>
            <div class="market-item-tag">常用场景：
              <div v-for="subItem in item.common_scene.split(',')">{{ subItem }}</div>
            </div>
          </router-link>
        </template>
        <el-empty v-else description="暂无算法"></el-empty>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import {CommonSceneQuery, LmpModelPageQuery} from "@/assets/js/api";

export default {
  name: "Supermarket",
  data() {
    return {
      page: 0,
      pageSize: 10,
      keywords: null,
      list: [],
      sceneList: [],
      loadStatus: false
    }
  },
  mounted() {
    this.initData()
    this.initScene()
  },
  methods: {
    initScene(){
      CommonSceneQuery({recommendFlag:'1'}).then(res=>{
        this.sceneList = res.data
      })
    },
    initData() {
      this.list = []
      this.loadStatus = true
      LmpModelPageQuery({
        modelName: this.keywords ? this.keywords : null,
        offsetPage: this.page,
        offsetSize: this.pageSize
      }).then(res => {
        this.list = res.data
        this.loadStatus = false
      }).catch(err => {
        this.loadStatus = false
      })
    },
    fastSearch(name) {
      this.keywords = name
      this.initData()
    }
  }
}
</script>

<style scoped>
@import "../assets/css/superMarket/superMarket.css";
</style>
